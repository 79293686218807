export enum TRANSLATION_KEYS {
  DEFAULT = 'bridging.error.defaultErrorMessage',
  BALANCE_EXCEEDED = 'bridging.error.balanceError',
  BALANCE_TOO_LOW = 'bridging.error.minAmount',
  BALANCE_TOO_HIGH = 'bridging.error.maxAmount',
  NO_CARDANO_WALLET = 'bridging.error.noCardanoWallet',
  WRONG_CARDANO_WALLET_NET = 'bridging.error.wrongCardanoWallet',
  WRONG_CARDANO_WALLET_DEV_MODE = 'bridging.error.wrongCardanoDevMode',
  WRONG_CARDANO_WALLET_PROD_MODE = 'bridging.error.wrongCardanoProdMode',
  ZERO_MILKOMEDA_ADDRESS = 'bridging.error.zeroAddress',
  ADA_FOR_TRANSACTION_TOO_LOW = 'bridging.error.moreAdaRequired',
  INPUT_LIMIT_EXCEEDED = 'bridging.error.inputLimitExceeded',
}

import { Token } from '@/sdk/entities/token';
import { compareTokenAddresses, fromWei, isZeroAddress, toWei } from '@/sdk/utils';
import { BLOCKCHAIN_NAME } from '@/store/modules/bridge/constants/BLOCKCHAIN_NAME';
import {
  getBlockchainById,
  getBlockchainByName,
  getNativeBlockchainToken,
} from '@/store/modules/bridge/constants/BLOCKCHAINS';
import { BRIDGE_NETWORKS_FEES } from '@/store/modules/bridge/constants/BRIDGE_NETWORKS_FEES';
import { BRIDGE_TOKENS } from '@/store/modules/bridge/constants/BRIDGE_TOKENS';
import { BRIDGE_TYPE } from '@/store/modules/bridge/constants/BRIDGE_TYPE';
import {
  GAS_TOKENS_FEE,
  RECEIVE_GAS_TOKENS,
} from '@/store/modules/bridge/constants/RECEIVE_GAS_TOKENS';
import {
  getBridgeBlockchainName,
  isCardanoMainnetOrTestnet,
  isMilkomedaMainnetOrTestnet,
} from '@/store/modules/bridge/helpers/cardano-bridge.helper';
import { Blockchain } from '@/store/modules/bridge/models/blockchain';
import { BridgeEvent, BridgeEventCommonPayload } from '@/store/modules/bridge/models/bridge-event';
import { BridgeForm } from '@/store/modules/bridge/models/bridge-form';
import { BridgeToken } from '@/store/modules/bridge/models/bridge-token';
import { Direction } from '@/store/modules/bridge/models/direction';
import { useCardanoTokens } from '@/store/modules/tokens/useCardanoTokens';
import { useAllWallets } from '@/store/modules/wallet/useAllWallets';
import { useCardanoWallet } from '@/store/modules/wallet/useCardanoWallet';
import { BIG_ZERO, max } from '@/utils/bigNumber';
import { Mitt, Emitter } from '@/utils/emitter';
import { getInstance } from '@snapshot-labs/lock/plugins/vue3';
import BigNumber from 'bignumber.js';
import { defineStore } from 'pinia';
import { computed, ComputedRef, reactive, ref, unref, watch } from 'vue';
import { useStore } from 'vuex';
import { ERROR_MESSAGES } from '@/store/modules/bridge/constants/ERROR_MESSAGES';
import { TRANSLATION_KEYS } from '@/store/modules/bridge/constants/ERROR_TRANSLATION_KEYS';

import {
  removeBridgeError,
  parseBridgeErrors,
} from '@/store/modules/bridge/helpers/bridge-error-handler.helper';
import { CRYPTO_SDK_VERSION } from 'crypto-sdk';
import {
  AVERAGE_FEE,
  MILKOMEDA_APPROVE_FEE,
  MILKOMEDA_BLOCKCHAIN_FEE,
  MILKOMEDA_RECEIVED_FOR_GAS,
  MILKOMEDA_TRANSACTION_FEE,
  MIN_ADA_BRIDGE,
  MIN_UTXO,
  MIN_UTXO_WITH_TOKENS,
} from '@/store/modules/bridge/constants/AMOUNT_LIMITS_FOR_BRIDGE';
import { format } from '@/utils/format';
import { NO_CONTRACT_ADDRESS } from '@/sdk/constants';
import { DEFAULT_NETWORK_ID } from '@/helpers/networkParams.helper';
import { useTokens } from '@/store/modules/tokens/useTokens';
import { useBalances } from '@/store/modules/tokens/useBalances';

console.debug('sdk v', CRYPTO_SDK_VERSION);

export const useCardanoBridge = defineStore('cardanoBridge', () => {
  const { state } = useStore();
  const { isWalletInjected } = useAllWallets();
  const cardanoWallet = useCardanoWallet();
  const { balances } = useBalances();

  const currentBlockChain = ref<BLOCKCHAIN_NAME>(getCurrentBlockchainsNames()[0]);
  console.log('currentBlockChain', currentBlockChain);

  const bridgeTokens = ref<BridgeToken[]>(getBridgeTokens());
  console.log('bridgeTokens', bridgeTokens);
  const bridgeForm = reactive<BridgeForm>(
    getInitializedBridgeForm(bridgeTokens.value as BridgeToken[], unref(currentBlockChain)),
  );

  const getBlockchainTokens = (
    blockchainName: BLOCKCHAIN_NAME | ComputedRef<BLOCKCHAIN_NAME>,
  ): ComputedRef<Token[]> => {
    return computed(() => {
      const bridgeTokensForThisBlockchain = bridgeTokens.value.filter(
        token =>
          token.blockchain1.name === unref(blockchainName) ||
          token.blockchain2.name === unref(blockchainName),
      );

      return bridgeTokensForThisBlockchain.map(bridgeToken =>
        bridgeToken.blockchain1.name === unref(blockchainName)
          ? bridgeToken.token1
          : bridgeToken.token2,
      );
    });
  };

  const getBlockchainByDirection = (
    direction: Direction | ComputedRef<Direction>,
  ): ComputedRef<Blockchain> => {
    return computed(() =>
      unref(direction) === 'from' ? bridgeForm.fromBlockchain : bridgeForm.toBlockchain,
    );
  };

  const getTokenByDirection = (
    direction: Direction | ComputedRef<Direction>,
  ): ComputedRef<Token> => {
    return computed(() =>
      unref(direction) === 'from' ? bridgeForm.fromToken : bridgeForm.toToken,
    );
  };

  const getAmountByDirection = (
    direction: Direction | ComputedRef<Direction>,
  ): ComputedRef<BigNumber | null> => {
    return computed(() =>
      unref(direction) === 'from'
        ? (bridgeForm.fromAmount as BigNumber)
        : (bridgeForm.toAmount as BigNumber),
    );
  };

  const getGasToken = () => {
    return isCardanoMainnetOrTestnet(bridgeForm.fromBlockchain.name)
      ? bridgeTokens.value[0].token1
      : bridgeTokens.value[0].token2;
  };

  const getMilkomedaGasTokenBalance = () => {
    return balances?.[getGasToken().unwrapWETH().symbol!]?.balance.toExact() || 0;
  };

  const getMilkomedaTokenBalance = (token: Token) => {
    return balances?.[token.unwrapWETH().symbol!]?.balance.toExact() || 0;
  };

  const setFromAmount = (amount: string, formFromAmount: string = amount): void => {
    bridgeForm.fromAmount = new BigNumber(amount);
    bridgeForm.formFromAmount = formFromAmount;
  };

  const calculateMaxAmount = (balance: BigNumber): BigNumber => {
    if (isCardanoMainnetOrTestnet(bridgeForm.fromBlockchain.name)) {
      console.log('setMaxFromAmount');
      return calculateCardanoMaxAmount(balance);
    } else if (isMilkomedaMainnetOrTestnet(bridgeForm.fromBlockchain.name)) {
      console.log('setMaxFromAmount');
      return calculateMilkomedaMaxAmount(balance);
    }
    return balance;
  };

  const resetBridgeErrors = () => {
    bridgeForm.errors = [];
  };

  const validateFormByNetworkAndToken = () => {
    resetBridgeErrors();
    if (isCardanoMainnetOrTestnet(bridgeForm.fromBlockchain.name)) {
      console.log('validation isCardanoMainnetOrTestnet');
      validateFromCardano();
    } else if (isMilkomedaMainnetOrTestnet(bridgeForm.fromBlockchain.name)) {
      console.log('validation isMilkomedaMainnetOrTestnet');
      validateFromMilkomeda();
    }
  };

  const setBalanceExceeded = (isBalanceExceeded: boolean) => {
    bridgeForm.errors = isBalanceExceeded
      ? parseBridgeErrors(ERROR_MESSAGES.BALANCE_EXCEEDED, bridgeForm.errors)
      : removeBridgeError(TRANSLATION_KEYS.BALANCE_EXCEEDED, bridgeForm.errors);
  };

  const setAmountTooLow = (isAmountTooLow: boolean, minAmount: string) => {
    bridgeForm.errors = isAmountTooLow
      ? parseBridgeErrors(ERROR_MESSAGES.BALANCE_TOO_LOW, bridgeForm.errors, {
          minAmount,
          tokenSymbol: bridgeForm.fromToken.unwrapWETH().symbol,
        })
      : removeBridgeError(TRANSLATION_KEYS.BALANCE_TOO_LOW, bridgeForm.errors);
  };

  const setAmountTooHigh = (isAmountTooHigh: boolean, maxAmount: string) => {
    bridgeForm.errors = isAmountTooHigh
      ? parseBridgeErrors(ERROR_MESSAGES.BALANCE_TOO_HIGH, bridgeForm.errors, {
          maxAmount,
          tokenSymbol: bridgeForm.fromToken.unwrapWETH().symbol,
        })
      : removeBridgeError(TRANSLATION_KEYS.BALANCE_TOO_HIGH, bridgeForm.errors);
  };

  const setIsEnoughAda = (isEnoughAda: boolean, amount: string) => {
    let tokenSymbol;

    if (isCardanoMainnetOrTestnet(bridgeForm.fromBlockchain.name)) {
      tokenSymbol = getNativeBlockchainToken(BLOCKCHAIN_NAME.CARDANO);
    } else if (isMilkomedaMainnetOrTestnet(bridgeForm.fromBlockchain.name)) {
      tokenSymbol = getNativeBlockchainToken(BLOCKCHAIN_NAME.MILKOMEDA);
    }

    bridgeForm.errors = !isEnoughAda
      ? parseBridgeErrors(ERROR_MESSAGES.ADA_FOR_TRANSACTION_TOO_LOW, bridgeForm.errors, {
          amount,
          tokenSymbol,
          network: bridgeForm.fromBlockchain.label,
        })
      : removeBridgeError(TRANSLATION_KEYS.ADA_FOR_TRANSACTION_TOO_LOW, bridgeForm.errors);
  };

  const setWrongCardanoMode = (isWrongCardanoNet: boolean | null) => {
    const supportedNetworkMode = process.env.VUE_APP_SUPPORTED_NETWORK_MODE;
    isWrongCardanoNet = isWrongCardanoNet && cardanoWallet.isInjected;

    if (isWrongCardanoNet && supportedNetworkMode === 'development') {
      bridgeForm.errors = parseBridgeErrors(
        ERROR_MESSAGES.WRONG_CARDANO_WALLET_DEV_MODE,
        bridgeForm.errors,
      );
    } else if (isWrongCardanoNet && supportedNetworkMode === 'production') {
      bridgeForm.errors = parseBridgeErrors(
        ERROR_MESSAGES.WRONG_CARDANO_WALLET_PROD_MODE,
        bridgeForm.errors,
      );
    } else {
      bridgeForm.errors = removeBridgeError(
        TRANSLATION_KEYS.WRONG_CARDANO_WALLET_DEV_MODE,
        bridgeForm.errors,
      );
      bridgeForm.errors = removeBridgeError(
        TRANSLATION_KEYS.WRONG_CARDANO_WALLET_PROD_MODE,
        bridgeForm.errors,
      );
    }
  };

  const setSelectedToken = (direction: Direction, token: Token): void => {
    const currentBlockchain = getBlockchainByDirection(direction).value;

    bridgeForm.bridgeToken = bridgeTokens.value.find(
      bridgeToken =>
        (compareTokenAddresses(bridgeToken.token1.address, token.address) &&
          bridgeToken.blockchain1 === currentBlockchain) ||
        (compareTokenAddresses(bridgeToken.token2.address, token.address) &&
          bridgeToken.blockchain2 === currentBlockchain),
    )!;

    const oppositeBridgeToken = compareTokenAddresses(
      bridgeForm.bridgeToken.token1.address,
      token.address,
    )
      ? bridgeForm.bridgeToken.token2
      : bridgeForm.bridgeToken.token1;

    if (direction === 'from') {
      bridgeForm.fromToken = token;
      bridgeForm.toToken = oppositeBridgeToken;
    } else {
      bridgeForm.toToken = token;
      bridgeForm.fromToken = oppositeBridgeToken;
    }
  };

  const executeBridgeSwap = (): Emitter<BridgeEvent> => {
    const emitter = Mitt<BridgeEvent>();
    const currentTransactionBridgeForm = Object.assign({}, bridgeForm);
    setFromAmount('0', '');

    if (isCardanoMainnetOrTestnet(bridgeForm.fromBlockchain.name)) {
      processCardanoBridgeSwap(currentTransactionBridgeForm as BridgeForm, state, emitter);
    } else if (isMilkomedaMainnetOrTestnet(bridgeForm.fromBlockchain.name)) {
      processMilkomedaBridgeSwap(currentTransactionBridgeForm as BridgeForm, state, emitter);
    }

    return emitter;
  };

  const onMilkomedaFormChange = async () => {
    checkAndSetReceiveGasTokensInMilkomeda(bridgeForm as BridgeForm);
    const { toAmount, networkFee } = estimateBridgeAmountOut(bridgeForm as BridgeForm);
    bridgeForm.toAmount = toAmount;
    bridgeForm.networkFee = networkFee;
  };

  const onCardanoFormChange = async () => {
    checkAndSetReceiveGasTokens(bridgeForm as BridgeForm);

    if (bridgeForm.fromAmount.gt(0)) {
      const allWalletsConnected = isWalletInjected(bridgeForm.fromBlockchain.name).value;
      // && isWalletInjected(bridgeForm.toBlockchain.name).value;

      if (allWalletsConnected) {
        bridgeForm.toAmount = null;
        bridgeForm.networkFee = null;

        try {
          const { toAmount, networkFee } = await fetchBridgeAmountOut(
            bridgeForm as BridgeForm,
            state,
          );
          bridgeForm.toAmount = toAmount;
          bridgeForm.networkFee = networkFee;
          bridgeForm.errors = removeBridgeError(TRANSLATION_KEYS.DEFAULT, bridgeForm.errors);
          return;
        } catch (e) {
          bridgeForm.toAmount = BIG_ZERO;
          bridgeForm.networkFee = BIG_ZERO;

          const errorMsg = e.toString().replace('Wallet error: ', '');
          bridgeForm.errors = parseBridgeErrors(errorMsg, bridgeForm.errors);
        }
      }

      const { toAmount, networkFee } = estimateBridgeAmountOut(bridgeForm as BridgeForm);
      bridgeForm.toAmount = toAmount;
      bridgeForm.networkFee = networkFee;
    } else {
      bridgeForm.toAmount = BIG_ZERO;
      bridgeForm.networkFee = BIG_ZERO;
    }
  };

  const onFormChange = async () => {
    if (isCardanoMainnetOrTestnet(bridgeForm.fromBlockchain.name)) {
      console.log('onFormChange Сardano');
      await onCardanoFormChange();
    } else if (isMilkomedaMainnetOrTestnet(bridgeForm.fromBlockchain.name)) {
      console.log('onFormChange milkomeda');
      await onMilkomedaFormChange();
    }
  };

  const switchBlockchains = (): void => {
    currentBlockChain.value = bridgeForm.toBlockchain.name;

    const newForm = getInitializedBridgeForm(
      bridgeTokens.value as BridgeToken[],
      unref(currentBlockChain),
    );

    bridgeForm.bridgeToken = newForm.bridgeToken;
    bridgeForm.fromBlockchain = newForm.fromBlockchain;
    bridgeForm.toBlockchain = newForm.toBlockchain;
    bridgeForm.fromToken = newForm.fromToken;
    bridgeForm.toToken = newForm.toToken;

    bridgeForm.fromAmount = BIG_ZERO;
    bridgeForm.toAmount = BIG_ZERO;
    bridgeForm.networkFee = BIG_ZERO;
    bridgeForm.receiveGasTokens = BIG_ZERO;
    bridgeForm.gasTokensFee = BIG_ZERO;
    bridgeForm.errors = [];

    validateFormByNetworkAndToken();
  };

  watch(() => bridgeForm.fromAmount, onFormChange);
  watch(() => bridgeForm.bridgeToken, onFormChange);
  watch(
    () => cardanoWallet.address,
    val => {
      if (val) {
        onFormChange();
      }
    },
  );

  return {
    bridgeTokens,
    bridgeForm,
    getBlockchainTokens,
    getBlockchainByDirection,
    getTokenByDirection,
    getAmountByDirection,
    getGasToken,
    setSelectedToken,
    setFromAmount,
    resetBridgeErrors,
    setBalanceExceeded,
    setAmountTooLow,
    setAmountTooHigh,
    setWrongCardanoMode,
    setIsEnoughAda,
    executeBridgeSwap,
    validateFormByNetworkAndToken,
    calculateMaxAmount,
    switchBlockchains,
    getMilkomedaGasTokenBalance,
    getMilkomedaTokenBalance,
  };
});

function getBridgeTokens(): BridgeToken[] {
  const currentBlockchainNames = getCurrentBlockchainsNames();

  const rawBridgeTokens = BRIDGE_TOKENS.filter(
    rawBridgeToken =>
      (rawBridgeToken.blockchain1 === currentBlockchainNames[0] &&
        rawBridgeToken.blockchain2 === currentBlockchainNames[1]) ||
      (rawBridgeToken.blockchain1 === currentBlockchainNames[1] &&
        rawBridgeToken.blockchain2 === currentBlockchainNames[0]),
  );

  return rawBridgeTokens.map(makeBridgeTokenByRawBridgeToken);
}

function getCurrentBlockchainsNames(): [BLOCKCHAIN_NAME, BLOCKCHAIN_NAME] {
  const milkomedaName = getBlockchainById(
    Number(DEFAULT_NETWORK_ID ?? getBlockchainByName(BLOCKCHAIN_NAME.MILKOMEDA).id),
  ).name;
  const cardanoName = getBlockchainById(
    Number(
      process.env.VUE_APP_DEFAULT_CARDANO_ID ?? getBlockchainByName(BLOCKCHAIN_NAME.CARDANO).id,
    ),
  ).name;

  return [cardanoName, milkomedaName];
}

function makeBridgeTokenByRawBridgeToken(
  rawBridgeToken: typeof BRIDGE_TOKENS[number],
): BridgeToken {
  const { getTokenByAddressAndChainId } = useTokens();

  const cardanoTokenNumber = isCardanoMainnetOrTestnet(rawBridgeToken.blockchain1) ? 1 : 2;
  const milkomedaTokenNumber = cardanoTokenNumber === 1 ? 2 : 1;
  const cardanoTokenAddress = rawBridgeToken[`tokenAddress${cardanoTokenNumber}`];
  const milkomedaTokenAddress = rawBridgeToken[`tokenAddress${milkomedaTokenNumber}`];

  const milkomedaToken = getTokenByAddressAndChainId(
    milkomedaTokenAddress,
    getBlockchainByName(rawBridgeToken[`blockchain${milkomedaTokenNumber}`]).id,
  );

  if (!milkomedaToken) {
    throw new Error(`Milkomeda token ${milkomedaTokenAddress} was not found.`);
  }

  const cardanoToken = useCardanoTokens().getTokenByAddress(cardanoTokenAddress);

  const cardanoTokenLikeERC20 = new Token(
    getBlockchainByName(rawBridgeToken[`blockchain${cardanoTokenNumber}`]).id,
    cardanoTokenAddress,
    cardanoToken.decimals,
    cardanoToken.symbol,
    cardanoToken.name,
    milkomedaToken.projectLink,
    milkomedaToken.tokenIconUrl,
  );

  return {
    blockchain1: getBlockchainByName(rawBridgeToken.blockchain1),
    blockchain2: getBlockchainByName(rawBridgeToken.blockchain2),
    token1: cardanoTokenNumber === 1 ? cardanoTokenLikeERC20 : milkomedaToken,
    token2: cardanoTokenNumber === 2 ? cardanoTokenLikeERC20 : milkomedaToken,
    blockchain1Fee: new BigNumber(rawBridgeToken.blockchain1Fee),
    blockchain2Fee: new BigNumber(rawBridgeToken.blockchain2Fee),
  };
}

function getInitializedBridgeForm(
  bridgeTokens: BridgeToken[],
  currentBlockChain: BLOCKCHAIN_NAME,
): BridgeForm {
  const defaultFormAmount = 0;
  const bridgeToken = bridgeTokens[0];
  const [fromBlockchain, toBlockchain] = isCardanoMainnetOrTestnet(currentBlockChain)
    ? [bridgeToken.blockchain1, bridgeToken.blockchain2]
    : [bridgeToken.blockchain2, bridgeToken.blockchain1];

  const [fromToken, toToken] = isCardanoMainnetOrTestnet(currentBlockChain)
    ? [bridgeToken.token1, bridgeToken.token2]
    : [bridgeToken.token2, bridgeToken.token1];

  return {
    bridgeToken,
    fromBlockchain,
    toBlockchain,
    fromToken,
    toToken,
    fromAmount: new BigNumber(defaultFormAmount),
    formFromAmount: '',
    toAmount: BIG_ZERO,
    networkFee: BIG_ZERO,
    receiveGasTokens: BIG_ZERO,
    gasTokensFee: BIG_ZERO,
    errors: [],
  };
}

function estimateBridgeAmountOut(bridgeForm: BridgeForm): {
  toAmount: BigNumber;
  networkFee: BigNumber;
} {
  const networkFee = BRIDGE_NETWORKS_FEES[bridgeForm.fromBlockchain.name];
  const bridgeFee =
    bridgeForm.fromBlockchain.name === bridgeForm.bridgeToken.blockchain1.name
      ? bridgeForm.bridgeToken.blockchain1Fee
      : bridgeForm.bridgeToken.blockchain2Fee;
  const toAmount = bridgeForm.toToken.unwrapWETH().isETHToken()
    ? max(bridgeForm.fromAmount.minus(bridgeFee), 0)
    : bridgeForm.fromAmount;

  return {
    toAmount,
    networkFee: new BigNumber(networkFee),
  };
}
function getFromTokenBlockchainFee(bridgeForm: BridgeForm): {
  blockchainFee: BigNumber;
} {
  const blockchainFee =
    bridgeForm.fromBlockchain.name === bridgeForm.bridgeToken.blockchain1.name
      ? bridgeForm.bridgeToken.blockchain1Fee
      : bridgeForm.bridgeToken.blockchain2Fee;

  return {
    blockchainFee,
  };
}

async function fetchBridgeAmountOut(
  bridgeForm: BridgeForm,
  vuexState: { wallet: { account: string } },
): Promise<{ toAmount: BigNumber; networkFee: BigNumber }> {
  const result = (await doBridge(bridgeForm, vuexState.wallet.account)) as {
    to: { fee: { quantity: string; decimals: number } };
    from: { fee: { quantity: string; decimals: number } };
  };

  const bridgeFeeRelative = fromWei(result.to.fee.quantity, result.to.fee.decimals);
  const networkFeeRelative = fromWei(result.from.fee.quantity, result.from.fee.decimals);
  const toAmount = bridgeForm.toToken.unwrapWETH().isETHToken()
    ? max(bridgeForm.fromAmount.minus(bridgeFeeRelative), 0)
    : bridgeForm.fromAmount;

  return {
    toAmount,
    networkFee: networkFeeRelative,
  };
}

async function doBridge(
  bridgeForm: BridgeForm,
  milkomedaAddress: string,
  doOnlyCall = true,
): Promise<unknown> {
  const cardanoWallet = useCardanoWallet();
  if (!cardanoWallet.wallet) {
    throw new Error(ERROR_MESSAGES.NO_CARDANO_WALLET);
  }

  if (!isCardanoMainnetOrTestnet(bridgeForm.fromBlockchain.name)) {
    throw new Error(ERROR_MESSAGES.WRONG_CARDANO_WALLET_NET);
  }

  if (isZeroAddress(milkomedaAddress)) {
    throw new Error(ERROR_MESSAGES.ZERO_MILKOMEDA_ADDRESS);
  }

  const cardanoAsset = {
    token: bridgeForm.fromToken.address,
    quantity: toWei(bridgeForm.fromAmount, bridgeForm.fromToken.decimals).toFixed(0),
  };

  const demoArg = {
    isDemo: doOnlyCall,
    ttl: 3600,
  };

  return cardanoWallet.wallet.bridge(
    cardanoAsset,
    {
      address: milkomedaAddress,
      chain: getBridgeBlockchainName(bridgeForm.toBlockchain.name),
    },
    BRIDGE_TYPE.MILKOMEDA,
    demoArg,
  );
}

function checkAndSetReceiveGasTokens(bridgeFrom: BridgeForm): void {
  if (!bridgeFrom.fromToken.unwrapWETH().isETHToken()) {
    bridgeFrom.receiveGasTokens = new BigNumber(RECEIVE_GAS_TOKENS);
    bridgeFrom.gasTokensFee = new BigNumber(GAS_TOKENS_FEE);
  } else {
    bridgeFrom.receiveGasTokens = BIG_ZERO;
    bridgeFrom.gasTokensFee = BIG_ZERO;
  }
}

function checkAndSetReceiveGasTokensInMilkomeda(bridgeFrom: BridgeForm): void {
  if (!bridgeFrom.fromToken.unwrapWETH().isETHToken()) {
    bridgeFrom.receiveGasTokens = new BigNumber(MILKOMEDA_RECEIVED_FOR_GAS);
    bridgeFrom.gasTokensFee = new BigNumber(MILKOMEDA_TRANSACTION_FEE);
  } else {
    bridgeFrom.receiveGasTokens = BIG_ZERO;
    bridgeFrom.gasTokensFee = BIG_ZERO;
  }
}

async function processCardanoBridgeSwap(
  bridgeFrom: BridgeForm,
  vuexState: { wallet: { account: string } },
  emitter: Emitter<BridgeEvent>,
): Promise<void> {
  const cardanoWallet = useCardanoWallet();
  const eventPayload: BridgeEventCommonPayload = {
    from: {
      blockchain: bridgeFrom.fromBlockchain,
      token: bridgeFrom.fromToken,
      amount: bridgeFrom.fromAmount,
    },
    to: {
      blockchain: bridgeFrom.toBlockchain,
      token: bridgeFrom.toToken,
      amount: bridgeFrom.toAmount as BigNumber,
    },
  };

  try {
    const bridgeSwap = (await doBridge(bridgeFrom, vuexState.wallet.account, false)) as {
      from: {
        tx: {
          hash: string;
          wait: () => Promise<void>;
        };
      };
      to: {
        tx: Promise<{
          hash: string;
          wait: () => Promise<void>;
        }>;
      };
    };
    // bridgeFrom.fromAmount = new BigNumber(0);
    const firstTx = bridgeSwap.from.tx;
    emitter.emit('step-1-progress', { ...eventPayload, transactionHash: firstTx.hash });

    await cardanoWallet.awaitForTransaction(firstTx.hash);
    emitter.emit('step-1-completed', { ...eventPayload, transactionHash: firstTx.hash });
    emitter.emit('step-2-progress', eventPayload);

    const secondTx = await bridgeSwap.to.tx;
    emitter.emit('step-2-completed', eventPayload);
    emitter.emit('step-3-progress', { ...eventPayload, transactionHash: secondTx.hash });

    await getInstance().web3.getSigner().provider.getTransaction(secondTx.hash);
    emitter.emit('step-3-completed', { ...eventPayload, transactionHash: secondTx.hash });
  } catch (e: unknown) {
    emitter.emit('error', { ...eventPayload, reason: e as Error });
  }
}

async function processMilkomedaBridgeSwap(
  bridgeFrom: BridgeForm,
  vuexState: { wallet: { account: string } },
  emitter: Emitter<BridgeEvent>,
) {
  const cardanoWallet = useCardanoWallet();
  const eventPayload: BridgeEventCommonPayload = {
    from: {
      blockchain: bridgeFrom.fromBlockchain,
      token: bridgeFrom.fromToken,
      amount: bridgeFrom.fromAmount,
    },
    to: {
      blockchain: bridgeFrom.toBlockchain,
      token: bridgeFrom.toToken,
      amount: bridgeFrom.toAmount as BigNumber,
    },
  };
  try {
    const tokenAddress = bridgeFrom.fromToken?.unwrapWETH().isETHToken()
      ? NO_CONTRACT_ADDRESS
      : bridgeFrom.fromToken.address;
    const milkomedaAsset = {
      token: tokenAddress,
      quantity: toWei(bridgeFrom.fromAmount, bridgeFrom.fromToken.decimals).toString(),
    };
    const from = {
      chain: getBridgeBlockchainName(bridgeFrom.fromBlockchain.name),
      address: vuexState.wallet.account,
    };
    const to = {
      chain: getBridgeBlockchainName(bridgeFrom.toBlockchain.name),
      address: cardanoWallet.address,
    };

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const bridgeSwap = (await cardanoWallet.bridgeProvider.bridgeFromEVM(
      milkomedaAsset,
      from,
      to,
      getInstance().web3.getSigner(),
    )) as {
      from: {
        tx: {
          hash: string;
          wait: () => Promise<void>;
        };
      };
      to: {
        tx: Promise<{
          hash: string;
          wait: (blockfrost) => Promise<void>;
        }>;
      };
    };

    const firstTx = bridgeSwap.from.tx;
    emitter.emit('step-1-progress', { ...eventPayload, transactionHash: firstTx.hash });

    await bridgeSwap.from.tx.wait();
    emitter.emit('step-1-completed', { ...eventPayload, transactionHash: firstTx.hash });
    emitter.emit('step-2-progress', eventPayload);

    const secondTx = await bridgeSwap.to.tx;
    emitter.emit('step-2-completed', eventPayload);
    emitter.emit('step-3-progress', { ...eventPayload, transactionHash: secondTx.hash });

    const thirdTx = await secondTx.wait(cardanoWallet.blockfrost);
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    emitter.emit('step-3-completed', { ...eventPayload, transactionHash: thirdTx });
  } catch (e: unknown) {
    emitter.emit('error', { ...eventPayload, reason: e as Error });
  }
}

function calculateMilkomedaMaxAmount(balance: BigNumber): BigNumber {
  const { bridgeForm } = useCardanoBridge();

  if (!bridgeForm.fromToken.unwrapWETH().isETHToken()) {
    return balance;
  }

  return max(balance.minus(MILKOMEDA_TRANSACTION_FEE).toFixed(6, BigNumber.ROUND_DOWN), 0);
}

function calculateCardanoMaxAmount(balance: BigNumber): BigNumber {
  const { bridgeForm } = useCardanoBridge();

  if (!bridgeForm.fromToken.unwrapWETH().isETHToken()) {
    return balance;
  }

  const lockedGasToken = calculateLockedGasTokenInCardano();
  return max(balance.plus(MIN_ADA_BRIDGE).minus(lockedGasToken), 0);
}

function calculateLockedGasTokenInCardano(): number {
  const { balances } = useCardanoTokens();

  const tokensCount = Object.values(unref(balances) ?? {}).filter(bal =>
    new BigNumber(bal).gt(0),
  ).length;

  return MIN_ADA_BRIDGE + (tokensCount > 1 ? MIN_UTXO_WITH_TOKENS : MIN_UTXO) + AVERAGE_FEE;
}

function validateFromCardano() {
  console.log('minADA', calculateLockedGasTokenInCardano());
  console.log('validate from cardano');
  const {
    bridgeForm,
    setBalanceExceeded,
    setAmountTooLow,
    setAmountTooHigh,
    getGasToken,
    setIsEnoughAda,
    setWrongCardanoMode,
  } = useCardanoBridge();
  const cardanoWallet = useCardanoWallet();
  const { tokenBalanceRelative } = useCardanoTokens();

  // validate enough ada
  const gasTokenBalance = tokenBalanceRelative(getGasToken().address).value;
  console.log('getGasToken().address', getGasToken().address);
  const lockedGasToken = calculateLockedGasTokenInCardano();

  setIsEnoughAda(gasTokenBalance.gte(lockedGasToken), lockedGasToken.toString());

  setWrongCardanoMode(!cardanoWallet.isNetworkSupported);

  if (bridgeForm.fromAmount.lte(0)) {
    return;
  }

  // validate balance exceed
  const fromBalanceRelative = tokenBalanceRelative(bridgeForm.fromToken.address).value;
  setBalanceExceeded(bridgeForm.fromAmount.gt(fromBalanceRelative));

  const minADAAmountForBridge = getFromTokenBlockchainFee(
    bridgeForm as BridgeForm,
  ).blockchainFee.plus(MIN_ADA_BRIDGE);

  // validate amount to low
  if (bridgeForm.fromToken.unwrapWETH().isETHToken()) {
    setAmountTooLow(
      bridgeForm.fromAmount.isLessThan(minADAAmountForBridge),
      minADAAmountForBridge.toFixed(),
    );
  } else {
    const minNonADAAmount = 1 / 10 ** bridgeForm.fromToken.decimals;
    setAmountTooLow(bridgeForm.fromAmount.isLessThan(minNonADAAmount), minNonADAAmount.toFixed());
  }

  // validate amount to high
  if (bridgeForm.fromToken.unwrapWETH().isETHToken()) {
    const bigADAMaxAmount = calculateCardanoMaxAmount(fromBalanceRelative);
    console.log('bigADAMaxAmount', bigADAMaxAmount.toFixed());
    console.log('bridgeForm.fromAmount', bridgeForm.fromAmount.toFixed());
    setAmountTooHigh(
      bridgeForm.fromAmount.gt(bigADAMaxAmount) &&
        !fromBalanceRelative.isLessThan(minADAAmountForBridge),
      format(bigADAMaxAmount.toFixed(2, BigNumber.ROUND_DOWN)),
    );
  }
}

function validateFromMilkomeda() {
  console.log('validateFromMilkomeda');

  const {
    bridgeForm,
    setBalanceExceeded,
    setAmountTooLow,
    setAmountTooHigh,
    setIsEnoughAda,
    getMilkomedaGasTokenBalance,
    getMilkomedaTokenBalance,
    setWrongCardanoMode,
  } = useCardanoBridge();
  const cardanoWallet = useCardanoWallet();

  const gasTokenBalance = new BigNumber(getMilkomedaGasTokenBalance());

  // validate enough ada
  let lockedGas = MILKOMEDA_BLOCKCHAIN_FEE + MILKOMEDA_TRANSACTION_FEE + MILKOMEDA_RECEIVED_FOR_GAS;
  if (!bridgeForm.fromToken.unwrapWETH().isETHToken()) {
    lockedGas = lockedGas + MILKOMEDA_APPROVE_FEE;
  }
  setIsEnoughAda(
    gasTokenBalance.gte(lockedGas),
    new BigNumber(lockedGas).toFixed(2, BigNumber.ROUND_UP),
  );
  console.log(gasTokenBalance.toFixed(), new BigNumber(lockedGas).toFixed(6, BigNumber.ROUND_UP));

  setWrongCardanoMode(!cardanoWallet.isNetworkSupported);

  if (bridgeForm.fromAmount.lte(0)) {
    return;
  }

  // validate balance exceed
  const fromBalanceRelative = getMilkomedaTokenBalance(bridgeForm.fromToken);
  setBalanceExceeded(bridgeForm.fromAmount.gt(fromBalanceRelative));

  const minAmountForBridge = MILKOMEDA_BLOCKCHAIN_FEE + MILKOMEDA_RECEIVED_FOR_GAS;

  // validate amount to low
  if (bridgeForm.fromToken.unwrapWETH().isETHToken()) {
    setAmountTooLow(
      bridgeForm.fromAmount.isLessThan(minAmountForBridge),
      minAmountForBridge.toFixed(),
    );
  } else {
    const minNonADAAmount = 1 / 10 ** bridgeForm.fromToken.decimals;
    setAmountTooLow(bridgeForm.fromAmount.isLessThan(minNonADAAmount), minNonADAAmount.toFixed());
  }

  // validate amount to high
  if (bridgeForm.fromToken.unwrapWETH().isETHToken()) {
    const bigADAMaxAmount = calculateMilkomedaMaxAmount(gasTokenBalance);
    // console.log('bigADAMaxAmount', bigADAMaxAmount.toFixed());
    // console.log('bridgeForm.fromAmount', bridgeForm.fromAmount.toFixed());
    setAmountTooHigh(
      bridgeForm.fromAmount.gt(bigADAMaxAmount) &&
        !new BigNumber(fromBalanceRelative).isLessThan(minAmountForBridge),
      format(bigADAMaxAmount.toFixed(2, BigNumber.ROUND_DOWN)),
    );
  }
}

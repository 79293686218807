export enum ERROR_MESSAGES {
  BALANCE_EXCEEDED = 'Amount exceeds balance',
  BALANCE_TOO_LOW = 'Amount is too low',
  BALANCE_TOO_HIGH = 'Amount is too high',
  NO_CARDANO_WALLET = 'Cardano wallet must be initialized before calculationg bridge swap.',
  WRONG_CARDANO_WALLET_NET = 'Bridge supports only cardano-milkomeda swaps',
  WRONG_CARDANO_WALLET_DEV_MODE = 'Your network is not supported. Switch to testnet in Cardano wallet.',
  WRONG_CARDANO_WALLET_PROD_MODE = 'Your network is not supported. Switch to mainnet in Cardano wallet.',
  ZERO_MILKOMEDA_ADDRESS = 'Receiver address must be not 0x00',
  ADA_FOR_TRANSACTION_TOO_LOW = 'You need more ADA in your Cardano wallet for the bridging fee',
  INPUT_LIMIT_EXCEEDED = 'INPUT_LIMIT_EXCEEDED',
}

export const MIN_UTXO = 1;
export const MIN_UTXO_WITH_TOKENS = 2;
export const MIN_ADA_BRIDGE = 2;
export const AVERAGE_FEE = 0.4;

export const MILKOMEDA_TRANSACTION_FEE = 0.02;
export const MILKOMEDA_APPROVE_FEE = 0.02;
export const MILKOMEDA_RECEIVED_FOR_GAS = 3;
export const MILKOMEDA_BLOCKCHAIN_FEE = 1;

export const DEFAULT_BRIDGE_DECIMALS = 6;

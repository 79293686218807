import mitt, { Emitter as OriginalEmitter, EventType } from 'mitt';

export type Emitter<Events extends Record<EventType, unknown>> = OriginalEmitter<Events> & {
  once: OriginalEmitter<Events>['on'];
};

export function Mitt<Events extends Record<EventType, unknown>>(): Emitter<Events> {
  const emitter: OriginalEmitter<Events> = mitt<Events>();
  (emitter as Emitter<Events>).once = (type, handler) => {
    const fn = (...args) => {
      emitter.off(type, fn);
      handler(...args);
    };

    emitter.on(type, fn);
  };

  return emitter as Emitter<Events>;
}

import { BridgeEventCommonPayload } from '@/store/modules/bridge/models/bridge-event';
import { format } from '@/utils/format';
import { ToastMessageOptions } from 'primevue/toast';
import { useToast } from 'primevue/usetoast';
import { useI18n } from 'vue-i18n';

export function useBridgeNotifications() {
  const toast = useToast();
  const { t, te } = useI18n();

  const getSwapDetails = (bridgeEventPayload: BridgeEventCommonPayload) =>
    `${t('bridging.bridging')} ${format(bridgeEventPayload.from.amount)} ${
      bridgeEventPayload.from.token.unwrapWETH().symbol
    } -> ${format(bridgeEventPayload.to.amount)} ${
      bridgeEventPayload.to.token.unwrapWETH().symbol
    } `;

  const commonSummary = step => t(`bridging.notification.step${step}.summary`);
  const successPrepend = step =>
    te(`bridging.notification.step${step}.successPrepend`)
      ? `<div>${t(`bridging.notification.step${step}.successPrepend`)}</div>`
      : '';

  const showInProgressNotification = (
    step: 1 | 2 | 3,
    bridgeEventPayload: BridgeEventCommonPayload,
    explorerLink?: string,
  ) => {
    toast.add({
      type: 'info',
      styleClass: `step${step}-progress bridge`,
      title: t(`bridging.notification.step${step}.title`, {
        fromBlockchain: bridgeEventPayload.from.blockchain.label,
        toBlockchain: bridgeEventPayload.to.blockchain.label,
      }),
      summary: `${commonSummary(step)} ${t('inProgress').toLowerCase()}`,
      detail:
        getSwapDetails(bridgeEventPayload) +
        '' +
        t(`bridging.notification.step${step}.info`) +
        '<div class="mt-s3">' +
        t('bridging.notification.estimatedTimeArrival') +
        '</div>',
      explorerLink: explorerLink,
      block: '',
    } as ToastMessageOptions);
  };
  const showSuccessNotification = (
    step: 1 | 2 | 3,
    bridgeEventPayload: BridgeEventCommonPayload,
    explorerLink?: string,
  ) => {
    toast.add({
      type: 'success',
      styleClass: `step${step}-success bridge`,
      title: t(`bridging.notification.step${step}.title`, {
        fromBlockchain: bridgeEventPayload.from.blockchain.label,
        toBlockchain: bridgeEventPayload.to.blockchain.label,
      }),
      summary: `${commonSummary(step)} ${t(`bridging.notification.completed`)}`,
      detail:
        successPrepend(step) +
        getSwapDetails(bridgeEventPayload) +
        '' +
        t(`bridging.notification.step${step}.success`, {
          fromBlockchain: bridgeEventPayload.from.blockchain.label,
          toBlockchain: bridgeEventPayload.to.blockchain.label,
        }),
      explorerLink: explorerLink,
    } as ToastMessageOptions);
    document.querySelector(`.step${step}-progress`)?.remove();
  };
  const showErrorNotification = (bridgeEventPayload: BridgeEventCommonPayload) => {
    toast.add({
      type: 'danger',
      styleClass: `bridge`,
      summary: t('bridging.notification.error'),
      detail:
        t('bridging.notification.sorry') +
        '<br>' +
        getSwapDetails(bridgeEventPayload) +
        '' +
        t(`bridging.notification.hasBeenFailed`),
    } as ToastMessageOptions);
  };
  const closeNotifications = () => {
    document.querySelectorAll('.p-toast-message.bridge').forEach(item => item.remove());
  };

  return {
    showInProgressNotification,
    showSuccessNotification,
    showErrorNotification,
    closeNotifications,
  };
}

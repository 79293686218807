import { Token } from '@/sdk/entities/token';
import { isCardanoMainnetOrTestnet } from '@/store/modules/bridge/helpers/cardano-bridge.helper';
import { useCardanoTokens } from '@/store/modules/tokens/useCardanoTokens';
import { BIG_ZERO } from '@/utils/bigNumber';
import { nativeBalanceWeiWithoutFee } from '@/utils/blockchain';
import BigNumber from 'bignumber.js';
import { computed, ComputedRef, unref } from 'vue';
import { fromWei } from '@/sdk/utils';
import { useBalances } from '@/store/modules/tokens/useBalances';

export function useTokenBalance(
  token: ComputedRef<Token | undefined> | Token | undefined,
  options: {
    inWei?: boolean;
    subTxFeeFromNativeBalance?: boolean;
  } = {},
): ComputedRef<string> {
  const { tokenBalanceWei } = useCardanoTokens();
  const { balances } = useBalances();

  return computed(() => {
    const uToken = unref(token);

    if (!uToken) {
      return '0';
    }

    let weiValue: string;

    if (isCardanoToken(uToken)) {
      weiValue = tokenBalanceWei(uToken.address).value.toFixed(0);
    } else {
      weiValue = balances?.[uToken.unwrapWETH().symbol!]?.balance.raw.toString() || '0';
    }

    if (options.subTxFeeFromNativeBalance && uToken.unwrapWETH().isETHToken()) {
      weiValue = nativeBalanceWeiWithoutFee(weiValue).toFixed(); // TODO handle cardano fee
    }

    if (options.inWei) {
      return new BigNumber(weiValue).toFixed(0);
    } else {
      return fromWei(weiValue, uToken.decimals).toFixed();
    }
  });
}

export function getTokenBalance(
  token?: Token,
  options: {
    inWei?: boolean;
    subTxFeeFromNativeBalance?: boolean;
  } = {},
): BigNumber {
  const { balances } = useBalances();

  if (!token) {
    return BIG_ZERO;
  }
  let weiValue = balances?.[token.unwrapWETH().symbol!]?.balance.raw.toString() || '0';

  if (options.subTxFeeFromNativeBalance && token.unwrapWETH().isETHToken()) {
    weiValue = nativeBalanceWeiWithoutFee(weiValue).toFixed();
  }

  if (options.inWei) {
    return new BigNumber(weiValue);
  } else {
    return fromWei(weiValue, token.decimals);
  }
}

function isCardanoToken(token: Token): boolean {
  return isCardanoMainnetOrTestnet(token.chainId);
}

import { BLOCKCHAIN_NAME } from '@/store/modules/bridge/constants/BLOCKCHAIN_NAME';
import { isCardanoMainnetOrTestnet } from '@/store/modules/bridge/helpers/cardano-bridge.helper';
import { useCardanoWallet } from '@/store/modules/wallet/useCardanoWallet';
import { computed, ComputedRef, unref } from 'vue';
import { useStore } from 'vuex';

export function useAllWallets() {
  const cardanoWallet = useCardanoWallet();
  const { state } = useStore();

  const isMilkomedaWalletInjected = computed(() => state.wallet.isInjected);
  const isCardanoWalletInjected = computed(() => cardanoWallet.isInjected);

  const isWalletInjected = (
    blockchain: BLOCKCHAIN_NAME | ComputedRef<BLOCKCHAIN_NAME>,
  ): ComputedRef<boolean> => {
    return computed<boolean>(() =>
      isCardanoMainnetOrTestnet(unref(blockchain))
        ? isCardanoWalletInjected.value
        : isMilkomedaWalletInjected.value,
    );
  };

  return {
    isWalletInjected,
  };
}

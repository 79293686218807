import {
  BridgeError,
  BalanceError,
  MinAmountError,
  MaxAmountError,
  CardanoWalletError,
  MilkomedaWalletError,
  CurrencyForFeeError,
} from '@/sdk/errors';
import { TRANSLATION_KEYS } from '@/store/modules/bridge/constants/ERROR_TRANSLATION_KEYS';
import { ERROR_MESSAGES } from '@/store/modules/bridge/constants/ERROR_MESSAGES';

const isSameBridgeErrorExists = (
  translationKey: string,
  bridgeErrors: Array<BridgeError>,
): boolean => {
  return !!bridgeErrors?.find(error => error.translationKey === translationKey) || false;
};

export const removeBridgeError = (
  translationKey: string,
  bridgeErrors: Array<BridgeError>,
): Array<BridgeError> => {
  return bridgeErrors?.filter(error => error.translationKey !== translationKey);
};

export const addBridgeError = (
  error: BridgeError,
  bridgeErrors: Array<BridgeError>,
): Array<BridgeError> => {
  return isSameBridgeErrorExists(error.translationKey, bridgeErrors)
    ? bridgeErrors
    : bridgeErrors.concat(error);
};

export const parseBridgeErrors = (
  errorMessage: string,
  bridgeErrors: Array<BridgeError>,
  payload?: any,
): Array<BridgeError> => {
  switch (errorMessage) {
    case ERROR_MESSAGES.INPUT_LIMIT_EXCEEDED:
      bridgeErrors = addBridgeError(
        new CardanoWalletError(TRANSLATION_KEYS.INPUT_LIMIT_EXCEEDED),
        bridgeErrors,
      );
      break;

    case ERROR_MESSAGES.BALANCE_EXCEEDED:
      bridgeErrors = addBridgeError(
        new BalanceError(TRANSLATION_KEYS.BALANCE_EXCEEDED),
        bridgeErrors,
      );
      break;

    case ERROR_MESSAGES.BALANCE_TOO_LOW:
      bridgeErrors = addBridgeError(
        new MinAmountError(TRANSLATION_KEYS.BALANCE_TOO_LOW, payload),
        bridgeErrors,
      );
      break;

    case ERROR_MESSAGES.BALANCE_TOO_HIGH:
      bridgeErrors = addBridgeError(
        new MaxAmountError(TRANSLATION_KEYS.BALANCE_TOO_HIGH, payload),
        bridgeErrors,
      );
      break;

    case ERROR_MESSAGES.NO_CARDANO_WALLET:
      bridgeErrors = addBridgeError(
        new CardanoWalletError(TRANSLATION_KEYS.NO_CARDANO_WALLET),
        bridgeErrors,
      );
      break;

    case ERROR_MESSAGES.WRONG_CARDANO_WALLET_NET:
      bridgeErrors = addBridgeError(
        new CardanoWalletError(TRANSLATION_KEYS.WRONG_CARDANO_WALLET_NET),
        bridgeErrors,
      );
      break;

    case ERROR_MESSAGES.WRONG_CARDANO_WALLET_DEV_MODE:
      bridgeErrors = addBridgeError(
        new CardanoWalletError(TRANSLATION_KEYS.WRONG_CARDANO_WALLET_DEV_MODE),
        bridgeErrors,
      );
      break;

    case ERROR_MESSAGES.WRONG_CARDANO_WALLET_PROD_MODE:
      bridgeErrors = addBridgeError(
        new CardanoWalletError(TRANSLATION_KEYS.WRONG_CARDANO_WALLET_PROD_MODE),
        bridgeErrors,
      );
      break;

    case ERROR_MESSAGES.ZERO_MILKOMEDA_ADDRESS:
      bridgeErrors = addBridgeError(
        new MilkomedaWalletError(TRANSLATION_KEYS.ZERO_MILKOMEDA_ADDRESS),
        bridgeErrors,
      );
      break;

    case ERROR_MESSAGES.ADA_FOR_TRANSACTION_TOO_LOW:
      bridgeErrors = addBridgeError(
        new CurrencyForFeeError(TRANSLATION_KEYS.ADA_FOR_TRANSACTION_TOO_LOW, payload),
        bridgeErrors,
      );
      break;

    default:
      // only show default error message if there are no other messages with potentially the same meaning
      if (!bridgeErrors.length) {
        bridgeErrors = addBridgeError(
          new BridgeError(TRANSLATION_KEYS.DEFAULT, payload || {}),
          bridgeErrors,
        );
      }
      break;
  }

  return bridgeErrors;
};
